import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={21}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.353 7.752V5.57a4.628 4.628 0 0 0-9.256-.02v2.2"
      stroke="#298EAE"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.602 19.727H4.848A3.848 3.848 0 0 1 1 15.879v-4.353a3.848 3.848 0 0 1 3.848-3.848h7.754a3.848 3.848 0 0 1 3.849 3.848v4.353a3.848 3.848 0 0 1-3.849 3.848v0ZM8.726 12.575v2.254"
      stroke="#298EAE"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from 'react';

export const PlayBtnIcon = () => {
  return (
    <div>
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30 18.7679C31.3333 19.5377 31.3333 21.4623 30 22.2321L17.25 29.5933C15.9167 30.3631 14.25 29.4008 14.25 27.8612L14.25 13.1388C14.25 11.5992 15.9167 10.6369 17.25 11.4067L30 18.7679Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

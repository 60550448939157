import React from 'react';

export const Bookmark2 = ({ color, ...props }: any) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
          fill={color ? color : '#292D32'}
        />
        <path
          d="M8.68 15.3299C8.26 15.3299 7.87 15.2299 7.53 15.0399C6.7 14.5699 6.25 13.6399 6.25 12.4199V2.43994C6.25 2.02994 6.59 1.68994 7 1.68994C7.41 1.68994 7.75 2.02994 7.75 2.43994V12.4199C7.75 13.0699 7.94 13.5499 8.27 13.7299C8.62 13.9299 9.16 13.8299 9.75 13.4799L11.07 12.6899C11.61 12.3699 12.38 12.3699 12.92 12.6899L14.24 13.4799C14.84 13.8399 15.38 13.9299 15.72 13.7299C16.05 13.5399 16.24 13.0599 16.24 12.4199V2.43994C16.24 2.02994 16.58 1.68994 16.99 1.68994C17.4 1.68994 17.74 2.02994 17.74 2.43994V12.4199C17.74 13.6399 17.29 14.5699 16.46 15.0399C15.63 15.5099 14.54 15.4099 13.47 14.7699L12.15 13.9799C12.09 13.9399 11.9 13.9399 11.84 13.9799L10.52 14.7699C9.9 15.1399 9.26 15.3299 8.68 15.3299Z"
          fill={color ? color : '#292D32'}
        />
        <path
          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
          fill={color ? color : '#292D32'}
        />
        <path
          d="M8.68 15.3299C8.26 15.3299 7.87 15.2299 7.53 15.0399C6.7 14.5699 6.25 13.6399 6.25 12.4199V2.43994C6.25 2.02994 6.59 1.68994 7 1.68994C7.41 1.68994 7.75 2.02994 7.75 2.43994V12.4199C7.75 13.0699 7.94 13.5499 8.27 13.7299C8.62 13.9299 9.16 13.8299 9.75 13.4799L11.07 12.6899C11.61 12.3699 12.38 12.3699 12.92 12.6899L14.24 13.4799C14.84 13.8399 15.38 13.9299 15.72 13.7299C16.05 13.5399 16.24 13.0599 16.24 12.4199V2.43994C16.24 2.02994 16.58 1.68994 16.99 1.68994C17.4 1.68994 17.74 2.02994 17.74 2.43994V12.4199C17.74 13.6399 17.29 14.5699 16.46 15.0399C15.63 15.5099 14.54 15.4099 13.47 14.7699L12.15 13.9799C12.09 13.9399 11.9 13.9399 11.84 13.9799L10.52 14.7699C9.9 15.1399 9.26 15.3299 8.68 15.3299Z"
          fill={color ? color : '#292D32'}
        />
      </svg>
    </div>
  );
};

import React, { SVGProps } from 'react';

export const CoursesIcon = (
  props: SVGProps<SVGSVGElement> & { active?: boolean; dark?: boolean }
) => {
  const { active = false, dark = false, color, ...rest } = props;
  return (
    <>
      <svg
        width="26"
        height="26"
        viewBox="0 1 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M12.0099 17C11.1599 17 10.2999 16.78 9.62995 16.35L3.60995 12.42C2.48995 11.69 1.81995 10.46 1.81995 9.11997C1.81995 7.77997 2.48995 6.54997 3.60995 5.81997L9.63995 1.89997C10.9799 1.02997 13.0699 1.02997 14.3999 1.90997L20.3899 5.83997C21.4999 6.56997 22.1699 7.79997 22.1699 9.12997C22.1699 10.46 21.4999 11.69 20.3899 12.42L14.3999 16.35C13.7299 16.79 12.8699 17 12.0099 17ZM12.0099 2.74997C11.4399 2.74997 10.8699 2.87997 10.4599 3.15997L4.43995 7.07997C3.73995 7.53997 3.32995 8.27997 3.32995 9.11997C3.32995 9.95997 3.72995 10.7 4.43995 11.16L10.4599 15.09C11.2899 15.63 12.7499 15.63 13.5799 15.09L19.5699 11.16C20.2699 10.7 20.6699 9.95997 20.6699 9.11997C20.6699 8.27997 20.2699 7.53997 19.5699 7.07997L13.5799 3.14997C13.1599 2.88997 12.5899 2.74997 12.0099 2.74997Z"
          fill={color? color : props.active ? '#EDD491' : dark? '#FFFFFF' : '#ACB7CA'}
        />
        <path
          d="M12.0001 22.75C11.5601 22.75 11.1101 22.69 10.7501 22.57L7.56006 21.51C6.05006 21.01 4.86006 19.36 4.87006 17.77L4.88006 13.08C4.88006 12.67 5.22006 12.33 5.63006 12.33C6.04006 12.33 6.38006 12.67 6.38006 13.08L6.37006 17.77C6.37006 18.71 7.15006 19.79 8.04006 20.09L11.2301 21.15C11.6301 21.28 12.3701 21.28 12.7701 21.15L15.9601 20.09C16.8501 19.79 17.6301 18.71 17.6301 17.78V13.14C17.6301 12.73 17.9701 12.39 18.3801 12.39C18.7901 12.39 19.1301 12.73 19.1301 13.14V17.78C19.1301 19.37 17.9501 21.01 16.4401 21.52L13.2501 22.58C12.8901 22.69 12.4401 22.75 12.0001 22.75Z"
          fill={color? color :props.active ? '#EDD491' : dark? '#FFFFFF' : '#ACB7CA'}
        />
        <path
          d="M21.4 15.75C20.99 15.75 20.65 15.41 20.65 15V9C20.65 8.59 20.99 8.25 21.4 8.25C21.81 8.25 22.15 8.59 22.15 9V15C22.15 15.41 21.81 15.75 21.4 15.75Z"
          fill={color? color :props.active ? '#EDD491' : dark? '#FFFFFF' : '#ACB7CA'}
        />
      </svg>
    </>
  );
};

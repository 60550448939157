import React from 'react';

export const CompletedMarkIcon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          minHeight: 22,
          minWidth: 22,
        }}
      >
        <circle cx="11" cy="11" r="11" fill="#000000" />
        <path
          d="M9.39262 15.9917C9.26154 16.1235 9.08279 16.1971 8.89703 16.1971C8.71127 16.1971 8.53252 16.1235 8.40144 15.9917L4.4437 12.0333C4.03293 11.6225 4.03293 10.9566 4.4437 10.5465L4.93929 10.0509C5.35007 9.64011 6.0153 9.64011 6.42607 10.0509L8.89703 12.5218L15.5739 5.84501C15.9846 5.43423 16.6506 5.43423 17.0606 5.84501L17.5562 6.3406C17.967 6.75137 17.967 7.41731 17.5562 7.82738L9.39262 15.9917Z"
          fill="white"
        />
      </svg>
    </>
  );
};

import React from 'react';

export const SocialShare = (props:any) => {
  return (
    <>
      <svg
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.803 6.86192C12.8672 6.86192 12.024 6.45779 11.4388 5.81493L6.17541 9.07482C6.31593 9.43455 6.39387 9.82527 6.39387 10.234C6.39387 10.6429 6.31593 11.0337 6.17541 11.3932L11.4388 14.6533C12.024 14.0104 12.8672 13.6061 13.803 13.6061C15.5659 13.6061 17 15.0403 17 16.8031C17 18.566 15.5659 20 13.803 20C12.0401 20 10.606 18.5659 10.606 16.803C10.606 16.3942 10.6841 16.0035 10.8244 15.6438L5.56116 12.3839C4.97597 13.0268 4.13284 13.431 3.19701 13.431C1.43414 13.431 0 11.9967 0 10.234C0 8.47116 1.43414 7.03702 3.19701 7.03702C4.13284 7.03702 4.97597 7.44115 5.56116 8.08416L10.8244 4.82426C10.6841 4.46454 10.606 4.07381 10.606 3.66491C10.606 1.90219 12.0401 0.468052 13.803 0.468052C15.5659 0.468052 17 1.90219 17 3.66491C17 5.42778 15.5659 6.86192 13.803 6.86192ZM11.7717 16.803C11.7717 17.923 12.683 18.8342 13.803 18.8342C14.923 18.8342 15.8342 17.923 15.8342 16.803C15.8342 15.683 14.923 14.7717 13.803 14.7717C12.683 14.7717 11.7717 15.683 11.7717 16.803ZM3.19701 8.20278C2.07685 8.20278 1.16561 9.11401 1.16561 10.234C1.16561 11.354 2.07685 12.2653 3.19701 12.2653C4.31702 12.2653 5.22811 11.354 5.22811 10.234C5.22811 9.11401 4.31702 8.20278 3.19701 8.20278ZM11.7717 3.66506C11.7717 4.78507 12.683 5.69631 13.803 5.69631C14.923 5.69631 15.8342 4.78507 15.8342 3.66506C15.8342 2.54505 14.923 1.63381 13.803 1.63381C12.683 1.63381 11.7717 2.54505 11.7717 3.66506Z"
          fill={props?.color}
        />
      </svg>
    </>
  );
};

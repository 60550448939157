import { SVGProps } from 'react';

export const FullStar = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.3797 1.26568L3.90881 4.17362L0.617288 4.64219C0.483134 4.66353 0.357742 4.71995 0.254943 4.80522C0.152143 4.8905 0.0759321 5.00132 0.0347182 5.12545C-0.00649567 5.24959 -0.0111112 5.38221 0.021381 5.5087C0.0538732 5.63519 0.122211 5.75062 0.218853 5.84226L2.59951 8.1067L2.03616 11.3051C2.01142 11.4328 2.0246 11.5645 2.07419 11.6854C2.12379 11.8062 2.20781 11.9113 2.31669 11.9886C2.42557 12.0659 2.55494 12.1124 2.69005 12.1228C2.82517 12.1332 2.96061 12.107 3.08095 12.0472L6.02605 10.5344L8.97005 12.0451C9.09039 12.1048 9.22583 12.131 9.36094 12.1207C9.49606 12.1103 9.62543 12.0638 9.73431 11.9865C9.84319 11.9091 9.92721 11.8041 9.9768 11.6832C10.0264 11.5624 10.0396 11.4307 10.0148 11.303L9.4526 8.10882L11.8333 5.84438C11.9299 5.75274 11.9982 5.63731 12.0307 5.51082C12.0632 5.38433 12.0586 5.25171 12.0174 5.12757C11.9762 5.00344 11.9 4.89262 11.7972 4.80735C11.6944 4.72207 11.569 4.66565 11.4348 4.64431L8.1433 4.17362L6.6724 1.26568C6.61418 1.14873 6.52258 1.04997 6.40819 0.980834C6.2938 0.911694 6.16131 0.875 6.02605 0.875C5.89079 0.875 5.7583 0.911694 5.64391 0.980834C5.52952 1.04997 5.43792 1.14873 5.3797 1.26568Z"
        fill={color || '#EDD491'}
      />
    </svg>
  );
};
